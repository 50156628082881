<template>
  <div style="margin: 3%">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="员工及权限设置教学" name="role">
        <el-row :gutter="20">
          <el-col :span="6">
            <video
                controls
                preload="none"
                :poster="require('../../assets/img/teaching/1.1.png')"
                webkit-playsinline="true"
                playsinline="true"
                x5-video-player-type="h5"
                x5-video-player-fullscreen="true"
                x-webkit-airplay="allow"
                x5-video-orientation="portraint"
                style="object-fit:fill;width: 100%;height: 100%;">
              <source src="https://teaching-video-1304365928.cos.ap-shanghai.myqcloud.com/1.1%E5%A6%82%E4%BD%95%E6%B7%BB%E5%8A%A0%E6%96%B0%E5%91%98%E5%B7%A5%20(%E6%96%B0%EF%BC%89.MP4"
                      type="video/mp4">
              <source src="video.ogg" type="video/ogg; codecs=dirac, speex">
            </video>
          </el-col>
          <el-col :span="6">
            <video
                controls
                preload="none"
                :poster="require('../../assets/img/teaching/1.2.png')"
                webkit-playsinline="true"
                playsinline="true"
                x5-video-player-type="h5"
                x5-video-player-fullscreen="true"
                x-webkit-airplay="allow"
                x5-video-orientation="portraint"
                style="object-fit:fill;width: 100%;height: 100%;">
              <source src="https://teaching-video-1304365928.cos.ap-shanghai.myqcloud.com/1.2%E6%B7%BB%E5%8A%A0%E5%BA%97%E9%93%BA.mp4"
                      type="video/mp4">
              <source src="video.ogg" type="video/ogg; codecs=dirac, speex">
            </video>
          </el-col>
          <el-col :span="6">
            <video
                controls
                preload="none"
                :poster="require('../../assets/img/teaching/1.3.png')"
                webkit-playsinline="true"
                playsinline="true"
                x5-video-player-type="h5"
                x5-video-player-fullscreen="true"
                x-webkit-airplay="allow"
                x5-video-orientation="portraint"
                style="object-fit:fill;width: 100%;height: 100%;">
              <source src="https://teaching-video-1304365928.cos.ap-shanghai.myqcloud.com/1.3%E6%88%BF%E9%97%B4%E5%8F%82%E6%95%B0%E8%AE%BE%E7%BD%AE.mp4"
                      type="video/mp4">
              <source src="video.ogg" type="video/ogg; codecs=dirac, speex">
            </video>
          </el-col>
          <el-col :span="6">
            <video
                controls
                preload="none"
                :poster="require('../../assets/img/teaching/1.4.png')"
                webkit-playsinline="true"
                playsinline="true"
                x5-video-player-type="h5"
                x5-video-player-fullscreen="true"
                x-webkit-airplay="allow"
                x5-video-orientation="portraint"
                style="object-fit:fill;width: 100%;height: 100%;">
              <source src="https://teaching-video-1304365928.cos.ap-shanghai.myqcloud.com/1.4%E6%B7%BB%E5%8A%A0%E5%AE%A2%E8%B5%84%E6%9D%A5%E6%BA%90%E6%B8%A0%E9%81%93.mp4"
                      type="video/mp4">
              <source src="video.ogg" type="video/ogg; codecs=dirac, speex">
            </video>
          </el-col>
        </el-row>
        <el-row :gutter="20" style="margin-top: 20px">
          <el-col :span="6">
            <video
                controls
                preload="none"
                :poster="require('../../assets/img/teaching/1.5.png')"
                webkit-playsinline="true"
                playsinline="true"
                x5-video-player-type="h5"
                x5-video-player-fullscreen="true"
                x-webkit-airplay="allow"
                x5-video-orientation="portraint"
                style="object-fit:fill;width: 100%;height: 100%;">
              <source src="https://teaching-video-1304365928.cos.ap-shanghai.myqcloud.com/1.5%E6%94%B6%E6%AC%BE%E6%96%B9%E5%BC%8F%E8%AE%BE%E7%BD%AE.mp4"
                      type="video/mp4">
              <source src="video.ogg" type="video/ogg; codecs=dirac, speex">
            </video>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="客资模块教学" name="customer">
        <el-row :gutter="20">
          <el-col :span="6">
            <video
                controls
                preload="none"
                :poster="require('../../assets/img/teaching/2.1.png')"
                webkit-playsinline="true"
                playsinline="true"
                x5-video-player-type="h5"
                x5-video-player-fullscreen="true"
                x-webkit-airplay="allow"
                x5-video-orientation="portraint"
                style="object-fit:fill;width: 100%;height: 100%;">
              <source src="https://teaching-video-1304365928.cos.ap-shanghai.myqcloud.com/2.1%E5%A6%82%E4%BD%95%E6%B7%BB%E5%8A%A0%E6%96%B0%E5%AE%A2%E8%B5%84.mp4"
                      type="video/mp4">
              <source src="video.ogg" type="video/ogg; codecs=dirac, speex">
            </video>
          </el-col>
          <el-col :span="6">
            <video
                controls
                preload="none"
                :poster="require('../../assets/img/teaching/2.2.png')"
                webkit-playsinline="true"
                playsinline="true"
                x5-video-player-type="h5"
                x5-video-player-fullscreen="true"
                x-webkit-airplay="allow"
                x5-video-orientation="portraint"
                style="object-fit:fill;width: 100%;height: 100%;">
              <source src="https://teaching-video-1304365928.cos.ap-shanghai.myqcloud.com/2.2%E5%AE%A2%E8%B5%84%E7%BC%96%E8%BE%91%E5%8A%9F%E8%83%BD%E5%8F%8A%E6%B7%BB%E5%8A%A0%E6%B2%9F%E9%80%9A.mp4"
                      type="video/mp4">
              <source src="video.ogg" type="video/ogg; codecs=dirac, speex">
            </video>
          </el-col>
          <el-col :span="6">
            <video
                controls
                preload="none"
                :poster="require('../../assets/img/teaching/2.3.png')"
                webkit-playsinline="true"
                playsinline="true"
                x5-video-player-type="h5"
                x5-video-player-fullscreen="true"
                x-webkit-airplay="allow"
                x5-video-orientation="portraint"
                style="object-fit:fill;width: 100%;height: 100%;">
              <source src="https://teaching-video-1304365928.cos.ap-shanghai.myqcloud.com/2.3%E5%AE%A2%E8%B5%84%E6%B7%BB%E5%8A%A0%E9%A2%84%E7%BA%A6.mp4"
                      type="video/mp4">
              <source src="video.ogg" type="video/ogg; codecs=dirac, speex">
            </video>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="预约模块教学" name="appoint">
        <el-row :gutter="20">
          <el-col :span="6">
            <video
                controls
                preload="none"
                :poster="require('../../assets/img/teaching/3.1.png')"
                webkit-playsinline="true"
                playsinline="true"
                x5-video-player-type="h5"
                x5-video-player-fullscreen="true"
                x-webkit-airplay="allow"
                x5-video-orientation="portraint"
                style="object-fit:fill;width: 100%;height: 100%;">
              <source src="https://teaching-video-1304365928.cos.ap-shanghai.myqcloud.com/3.1%E9%A2%84%E7%BA%A6%E6%A1%A3%E6%9C%9F%E5%88%97%E8%A1%A8%E5%8F%8A%E7%9B%B8%E5%85%B3%E6%93%8D%E4%BD%9C.mp4"
                      type="video/mp4">
              <source src="video.ogg" type="video/ogg; codecs=dirac, speex">
            </video>
          </el-col>
        </el-row>

      </el-tab-pane>
      <el-tab-pane label="订单模块教学 " name="order">
        <el-col :span="6">
          <video
              controls
              preload="none"
              :poster="require('../../assets/img/teaching/4.1.png')"
              webkit-playsinline="true"
              playsinline="true"
              x5-video-player-type="h5"
              x5-video-player-fullscreen="true"
              x-webkit-airplay="allow"
              x5-video-orientation="portraint"
              style="object-fit:fill;width: 100%;height: 100%;">
            <source src="https://teaching-video-1304365928.cos.ap-shanghai.myqcloud.com/4.1%E6%B7%BB%E5%8A%A0%E8%AE%A2%E5%8D%95.mp4"
                    type="video/mp4">
            <source src="video.ogg" type="video/ogg; codecs=dirac, speex">
          </video>
        </el-col>
      </el-tab-pane>
<!--      <el-tab-pane label="婚纱管理模块教学 " name="clothes"></el-tab-pane>-->
    </el-tabs>
  </div>
</template>

<script>
export default {
name: "home",
  data(){
  return{
    activeName:"role",
  }
  },
  methods:{
    handleClick(tab, event) {

    }
  }
}
</script>

<style scoped>

</style>